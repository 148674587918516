import dayjs from 'dayjs';
import DayJSCustomParseFormat from 'dayjs/plugin/customParseFormat';
import DayJSUtc from 'dayjs/plugin/utc';
import DayJSTimezone from 'dayjs/plugin/timezone';
import DayJSAdvancedFormat from 'dayjs/plugin/advancedFormat';
import IsBetween from 'dayjs/plugin/isBetween';
import IsTomorrow from 'dayjs/plugin/isTomorrow';
import IsToday from 'dayjs/plugin/isToday';
import IsYesterday from 'dayjs/plugin/isYesterday';
import moment from 'moment-timezone';
import type { UtcTimezoneInfo } from '@/types/common';
import type { Timezone } from '@/types/country-timezone';

dayjs.extend(DayJSCustomParseFormat);
dayjs.extend(DayJSUtc);
dayjs.extend(DayJSTimezone);
dayjs.extend(DayJSAdvancedFormat);
dayjs.extend(IsBetween);
dayjs.extend(IsTomorrow);
dayjs.extend(IsToday);
dayjs.extend(IsYesterday);

export const isFutureTime = (firstDateTime: string | Date, secondDateTime: string | Date, time: string, timezone: string): boolean => {
	// Get the current time
	const currentTime = dayjs(firstDateTime).tz(timezone).format(DATE_TIME_ISO8601_FORMAT);
	const currentDateTimeObj = dayjs(currentTime);

	// Parse the date and time values
	const dateObj = dayjs(secondDateTime, DATE_TIME_ISO8601_FORMAT);
	const timeObj = dayjs(time, TIME_12_HOUR_2_DIGITS_FORMAT);

	// Combine them by setting the time of the date
	const futureTime = dateObj
		.set('hour', timeObj.get('hour'))
		.set('minute', timeObj.get('minute'));
	const futureTimeUtc = futureTime.format(DATE_TIME_ISO8601_FORMAT);
	return currentDateTimeObj.isBefore(futureTimeUtc);
};

/**
 * Convert from dateTime (UTC) to time format
 * Ex: Given format 'h:mm A'
 * Result: '2023-05-19T15:30:00Z' --> '03:30 PM'
 */
export const convertTimeFromUtc = (dateTimeUTC: string) => {
	if (!dateTimeUTC) {
		return '';
	}

	return dayjs(dateTimeUTC, DATE_TIME_ISO8601_FORMAT, true).format(TIME_12_HOUR_2_DIGITS_FORMAT);
};

/**
 * Convert dateTime with target timezone to dateTime(UTC)
 * Ex:
 * Timezone: New York
 * DateTime with timezone: 2023-12-21T05:30:00Z
 * Result: '2023-12-21T05:30:00Z' --> '2023-12-21T10:30:00Z'
 */
export const convertDateTimeToUtc = (dateTime: string | Date, timezone?: string, format = DATE_TIME_ISO8601_FORMAT) => {
	if (!dateTime) {
		return '';
	}

	const targetTimezone = timezone || dayjs.tz.guess();
	return dayjs.tz(dateTime, targetTimezone).utc().format(format);
};

/**
 * Convert dateTime to display format without timezone conversion
 * Ex: Given format 'YYYY-MM-DD HH:mm'
 * Result: '2023-12-21T13:30:00Z' --> '21-Dec-2023 13:30'
 */
export const convertDateTimeWithStrictTimezone = (dateTime: string, timezone: string, format = DISPLAY_DATE_FORMAT) => {
	if (!dateTime || !timezone) {
		return '';
	}
	return dayjs.tz(dateTime, timezone).format(format);
};

/**
 * Convert date and time to dateTime UTC with or without timezone
 * Ex: If timezone not passing
 * Date: '2023-12-21'
 * Time '03:30 PM'
 * Result: 2023-12-21T15:30:00Z
 *
 * Ex: If timezone passing
 * Date: '2023-12-21'
 * Time '03:30 PM'
 * Timezone: New York
 * Result: 2023-12-21T10:30:00Z
 */
export const convertDateAndTimeToUtc = (date: string, time: string, timezone?: string) => {
	if (!date && !time) {
		return '';
	}
	const dateObj = dayjs(date, DATE_TIME_ISO8601_FORMAT, true);
	const timeObj = dayjs(time || '12:00 AM', TIME_12_HOUR_2_DIGITS_FORMAT, true);
	const dateTime = dateObj.hour(timeObj.hour()).minute(timeObj.minute());
	const strDateTime = dateTime.format(DATE_TIME_ISO8601_FORMAT);

	if (timezone) {
		return convertDateTimeToUtc(strDateTime, timezone);
	}
	return strDateTime;
};

/**
 * Convert dateTime (UTC) to display dateTime format with target timezone
 * Ex:
 * DateTime UTC: '2023-12-21T21:00:00Z'
 * Display Format: 'YYYY-MM-DD h:mm A'
 * Timezone: New York
 * Result: '2023-12-21 04:00 PM'
 */
export const convertDateTimeFromUtcToTimezone = (dateTime: string | Date, timezone: string, format = DISPLAY_DATE_FORMAT) => {
	if (!dateTime) {
		return '';
	}
	return dayjs(dateTime).tz(timezone).format(format);
};

export const getTimezoneAbbreviation = (timezone: string) => {
	const tz = moment.tz(timezone).format('z');
	if (tz.includes('+')) {
		// Return GMT format for timezone that's not support abbreviation e.g. GMT+7
		return dayjs.tz(new Date(), timezone).format('z');
	}

	// Return timezone abbreviation
	return moment.tz(timezone).format('z');
};

export const calculateNumberOfDays = (startDate: string, endDate: string) => {
	// Parse the start and end date strings into Day.js objects
	const startDateObj = dayjs(startDate);
	const endDateObj = dayjs(endDate);

	// Calculate the difference in days
	return endDateObj.diff(startDateObj, 'days');
};

export const roundToNearestHour = (date: Date): Date => {
	const cloneDateObject = new Date(date);
	cloneDateObject.setMinutes(cloneDateObject.getMinutes() + 30);
	cloneDateObject.setMinutes(0, 0, 0);
	if (cloneDateObject < date) {
		// Add 30 min if date was round down
		cloneDateObject.setMinutes(cloneDateObject.getMinutes() + 30);
	}
	return cloneDateObject;
};

export const isBetween = (targetDateTime: string | Date, startDateTime: string | Date, endDateTime: string | Date): boolean => {
	return dayjs(targetDateTime).isBetween(startDateTime, endDateTime);
};

export const isToday = (dateTime: string | Date, timezone: string): boolean => {
	if (!dateTime) {
		return false;
	}
	return dayjs.tz(dateTime, timezone).isToday();
};

export const isTomorrow = (dateTime: string | Date, timezone: string): boolean => {
	if (!dateTime) {
		return false;
	}
	return dayjs.tz(dateTime, timezone).isTomorrow();
};

export const isYesterday = (dateTime: string | Date, timezone: string): boolean => {
	if (!dateTime) {
		return false;
	}
	return dayjs.tz(dateTime, timezone).isYesterday();
};

export const convertToDisplayStartAndEndDateTime = (startDateTime: string, endDateTime: string, timezone: string) => {
	if (!startDateTime || !endDateTime || !timezone) {
		return '';
	}

	const isSameDay = dayjs(startDateTime).isSame(endDateTime, 'date');
	const timezoneAbbr = getTimezoneAbbreviation(timezone);
	let dateTime = '';
	if (isSameDay) {
		const formattedDate = convertDateTimeFromUtcToTimezone(startDateTime, timezone);
		const formattedStartTime = convertDateTimeFromUtcToTimezone(startDateTime, timezone, TIME_12_HOUR_FORMAT);
		const formattedEndTime = convertDateTimeFromUtcToTimezone(endDateTime, timezone, TIME_12_HOUR_FORMAT);
		dateTime = `${formattedDate}\n${formattedStartTime} - ${formattedEndTime}`;
	} else {
		const formattedStartDate = convertDateTimeFromUtcToTimezone(startDateTime, timezone);
		const formattedStartTime = convertDateTimeFromUtcToTimezone(startDateTime, timezone, TIME_12_HOUR_FORMAT);
		const formattedEndDateTime = convertDateTimeFromUtcToTimezone(endDateTime, timezone, `${DISPLAY_DATE_FORMAT} ${TIME_12_HOUR_FORMAT}`);
		dateTime = `${formattedStartDate}\n${formattedStartTime} - ${formattedEndDateTime}`;
	}

	return `${dateTime} ${timezoneAbbr}`;
};

export const calculateHappeningNow = (start: UtcTimezoneInfo, end: UtcTimezoneInfo) => {
	const currentDateTime = convertDateTimeFromUtcToTimezone(new Date(), start.timezone, DATE_TIME_ISO8601_FORMAT);
	const startDateTime = convertDateTimeFromUtcToTimezone(start.utc, start.timezone, DATE_TIME_ISO8601_FORMAT);
	const endDateTime = convertDateTimeFromUtcToTimezone(end.utc, end.timezone, DATE_TIME_ISO8601_FORMAT);
	const isHappeningNow = isBetween(currentDateTime, startDateTime, endDateTime);

	return isHappeningNow;
};

export const convertDisplaySelectedTimezone = (timezone: Timezone) => {
	const timezoneName = timezone.name;
	const timezoneOffset = timezone.timezoneOffset ? `(GMT${timezone.timezoneOffset})` : '';
	return `${timezoneName} ${timezoneOffset}`;
};

export const getCurrentUtcDateTimeInSpecificTimezone = (timezone: string) => {
	// Get current time in specific timezone
	const currentDateTime = dayjs().tz(timezone).format(DATE_TIME_ISO8601_FORMAT);
	// Convert the current time in that timezone to UTC timezone
	const currentDateTimeToUtc = convertDateTimeToUtc(currentDateTime, timezone);
	return currentDateTimeToUtc;
};